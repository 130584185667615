import React, { useState } from "react"
import { navigate } from "gatsby"
import styled from "styled-components"

import { postData } from "../services/server"

import Page from "../components/layout/Page"
import PageHeader from "../components/layout/PageHeader"
import SEO from "../components/seo"

import EmailLookupForm from "../components/forms/EmailLookupForm"
import TextField from "../components/fields/TextField"
import EmailField from "../components/fields/EmailField";
import BlueButton from "../components/buttons/BlueButton"

import { colors, devices } from "../settings"

import signsIcon from "../images/icons/mysigns.svg"
import projectsIcon from "../images/icons/customprojects.svg"
import ordersIcon from "../images/icons/orderhistory.svg"
import trackIcon from "../images/icons/trackorder2.svg"

const Menu = styled.div`
`
const MenuItemBox = styled.div`
  border: 1px solid ${props => props.open ? "#ccc": "white"};
  border-radius: 3px;
  padding: 20px 25px;
  margin-bottom: 10px;
  cursor: pointer;
  background-color: ${props => props.open ? "white": colors.lightGrey};
  box-shadow: ${props => props.open ? "2px 2px 5px #ccc":"none"};
  text-align: left;

  > div.prompt {
    display: flex;

    img {
      width: 40px;
      height: 40px;
      margin-right: 10px;
      color: ${colors.orange};  
    }
  }
  > div.contents {
    margin-top: 15px;

    > div.form {
      display: flex;
    }
  }
  > div > label {
    margin-top: 8px;
  }

  &:hover {
    background-color: white;
    border: 1px solid #ccc;
    box-shadow: 2px 2px 5px #ccc;
  }

  .MuiOutlinedInput-root {
    margin-right: 5px;
  }

  label {
    cursor: pointer;
  }

  @media ${devices.mobile} {
    input {
      width: 100%;
    }
  }
`

const Error = styled.div`
  color: red;
  padding: 15px 0px;
`

const MenuItem = (props) => (
  <MenuItemBox {...props}>
    <div className="prompt">
      <img src={props.icon} alt={props.label} />
      <label>{props.label}</label>
    </div>
    {props.open && 
      <div className="contents">
        {props.children}
      </div>
    }
  </MenuItemBox>
)

const AccountPage = () => {
  const [email, setEmail] = useState("");
  const [orderNo, setOrderNo] = useState("");

  const [menuItem, setMenuItem] = useState("signs");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  function checkOrder(e,) {
    e.preventDefault();
    e.stopPropagation();

    setLoading(true);
    setError(null);

    postData("/api/orders/lookup/email", { email: email, orderNo: orderNo},
      (data) => {
          if (data && data.uid) {
            navigate("/account/order?uid=" + data.uid)
          }
          else {
            setError("Order not found.  Please verify you entered the email address and order number correctly.")
          }
      },
      (error) => {
        console.log(error);
        setError("Error looking up information.")
      },
      () => {
        setLoading(false);
      }
    );
  }
  
  function onMenuItem(name) {
    setMenuItem(name);
    setError(null);
    setLoading(false);
  }

  return (
    <>
      <SEO page="account" title="Your Account | Projects and Designs" />
      <Page simple center>
        <PageHeader title="Your Account" />
        <Menu>
          <MenuItem label="My Sign Designs" icon={signsIcon} onClick={() => onMenuItem("signs")} open={menuItem==="signs"}>
            <EmailLookupForm      
              password={false}
              onResult={(data) => {
                if (data && data.user && data.stats.signs > 0) {
                  navigate("/account/signs")
                }
                else {
                  setError("No data found.  Please verify you entered the email address correctly.")
                }
              }}
            />
            { error && <Error>{error}</Error>}
          </MenuItem>
          <MenuItem label="Custom Projects" icon={projectsIcon} onClick={() => onMenuItem("quotes")} open={menuItem==="quotes"}>
            <EmailLookupForm 
              password={false}                        
              onResult={(data) => {
                if (data && data.user && data.stats.quotes > 0) {
                  navigate("/account/projects")
                }
                else {
                  setError("No data found.  Please verify you entered the email address correctly.")
                }
              }}
            />
          </MenuItem>
          <MenuItem label="Track Your Order" icon={ordersIcon} onClick={() => onMenuItem("track")} open={menuItem==="track"}>
            <EmailField
                name="email"
                label="Email Address"
                value={email}
                onChange={(e) => {
                    setEmail(e.target.value) 
                }}
                wide
                autoComplete="email"
            />
            <div className="form" style={{marginTop:"12px"}}>
              <TextField 
                name="orderno" 
                label="Order #" 
                value={orderNo} 
                onChange={(e) => setOrderNo(e.target.value)} 
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    checkOrder(e)
                  } 
                }}
                wide
              >
                <BlueButton 
                  label="Retrieve" 
                  onClick={(e) => checkOrder(e)}
                  disabled={email.length === 0 || orderNo.length === 0}
                  busy={loading} 
                />
              </TextField>
            </div>
            { error && <Error>{error}</Error>}
          </MenuItem>
          <MenuItem label="Order History" icon={trackIcon} onClick={() => onMenuItem("orders")} open={menuItem==="orders"}>
            <EmailLookupForm 
              password={true}             
              onResult={(data) => {
                if (data && data.user && data.stats.orders > 0) {
                  navigate("/account/orders")
                }
                else {
                  setError("No data found.  Please verify you entered the email address correctly.")
                }
              }}
              onError={(error) => setError(error)}
            />
            { error && <Error>{error}</Error>}
          </MenuItem>
        </Menu>
      </Page>
    </>
  )
}

export default AccountPage
